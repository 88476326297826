
const dialogPull = [] as any[];
const activeDialog = computed(() => dialogPull.slice(-1)[0]);

function addDialogToPull(dialog: any) {
    dialogPull.push({ dialogState: dialog });
}

function removeDialogFromPull(dialog: any) {
    const index = dialogPull.findIndex(d => d.dialogState == dialog)
    if (index >= 0) {
        dialogPull.splice(index, 1);
    }
}

function escHandler(e: any) {
    const dialogState = dialogPull.slice(-1)[0];

    if (!dialogState || e.key !== "Escape") return;

    //close active dialog
    dialogState.dialogState.value = false;
}

function addEventListeners() {
    document.addEventListener('keydown', escHandler);
}

export function useDialog(dialog: any) {

    watchEffect(() => {
        if (dialog.value)
            addDialogToPull(dialog);
        else
            removeDialogFromPull(dialog);
    })

    return {
        dialog,
    }
}

export {
    activeDialog
}

if (process.client) {
    setTimeout(() => addEventListeners())    
}
